import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/route";
import { createTheme, ThemeProvider } from "@mui/material";
import {
  NotificationContainer,
  // NotificationManager,
  // @ts-ignore
} from "react-notifications";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const themes = createTheme({
  palette: {
    primary: {
      main: "#CB6CE6", // Your custom primary color
    },
  },
  typography: {
    fontFamily: `'Inter', sans-serif`, // Global font family
  },
});
root.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={themes}> */}
      <NotificationContainer />
      <Toaster position="top-right" />

      <Router>
        <AppRoutes />
      </Router>
    {/* </ThemeProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
