import {
  CssBaseline,
  Typography,
  Container,
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Alert,
  useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { backgroundColor } from "../assets/colors";
import Whitelogo from "../assets/whiteLongLogo.png";
import blacklogo from "../assets/blackLongLogo.png";



const Login = ({ submitData, setId }: any) => {
  const theme = useTheme()
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  
const topdiv = {
  height: "100vh",
  padding: "10px",
  background: theme.palette.rightPanel?.background,
};
  useEffect(() => {
    if (id) {
      setId(id);
    }
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div style={topdiv}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: theme.palette.sidebar?.background,
            borderRadius: "30px",
            padding: "30px",
          }}
        >
          {
            <img
            src={theme.palette.mode === 'dark' ?  Whitelogo : blacklogo} 
            style={{ cursor: "pointer", width: "180px", marginBottom:'20px' }}
            onClick={() => navigate("chat/")}
          />
          }
          <Typography component="h1" variant="h5">
            Log In
          </Typography>
          {errorMessage && (
            <Alert severity="error" onClose={() => setErrorMessage("")}>
              {errorMessage}
            </Alert>
          )}
          <form noValidate>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    autoFocus
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                    value={email}
                    {...register("email", {
                      required: "Email is required!",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address!",
                      },
                    })}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email?.message as any}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    // rules={{ required: "Password is required!" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="normal"
                        // required
                        fullWidth
                        id="password"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        // error={!!errors.password}
                        // helperText={
                        //   errors.password
                        //     ? typeof errors.password.message === "string"
                        //       ? errors.password.message
                        //       : "Invalid input"
                        //     : ""
                        // }
                      />
                    )}
                  />
                </Grid>
           
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                </Grid>
              </Grid>
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit(submitData)}
              >
                Log In
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    onClick={() => navigate("/register")}
                    variant="text"
                    sx={{
                      textTransform: 'none',
                      textDecoration: "underline",
                      "&:hover": {
                        textDecoration: "underline", // Keep underline on hover
                      },
                    }}
                  >
                    Don't have an account register here
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    onClick={() => navigate("/")}
                    variant="text"
                    sx={{
                      textTransform: 'none',
                      textDecoration: "underline",
                      "&:hover": {
                        textDecoration: "underline", // Keep underline on hover
                      },
                    }}
                  >
                   Home
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Container>
    </div>
  );
};
export default Login;
