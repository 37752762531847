import React, { createContext, useState, useContext } from 'react';

// Create the context
const BookmarkContext = createContext();

// Create a provider component
export const BookmarkProvider = ({ children }) => {
  const [bookMark, setBookMark] = useState([]);

  return (
    <BookmarkContext.Provider value={{ bookMark, setBookMark }}>
      {children}
    </BookmarkContext.Provider>
  );
};

// Custom hook for easier access to the context
export const useBookmark = () => {
  return useContext(BookmarkContext);
};
