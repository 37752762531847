import { useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const theme = useTheme()
  const [displayText, setDisplayText] = useState("");
  const [wordIndex, setWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const words = ["Inventors", "Attorneys", "Entrepreneurs", "Everyone"];
  const navigate = useNavigate()

  useEffect(() => {
    let timer;
    const currentWord = words[wordIndex];

    const animateText = () => {
      if (!isDeleting) {
        // Typing
        if (displayText.length < currentWord.length) {
          setDisplayText(currentWord.slice(0, displayText.length + 1));
          timer = setTimeout(animateText, 100); // Consistent typing speed
        } else {
          // Word complete - wait before deleting
          timer = setTimeout(() => {
            setIsDeleting(true);
            animateText();
          }, 2000);
        }
      } else {
        // Deleting
        if (displayText.length === 0) {
          setIsDeleting(false);
          setWordIndex((prev) => (prev + 1) % words.length);
          timer = setTimeout(animateText, 500); // Pause before next word
        } else {
          setDisplayText(currentWord.slice(0, displayText.length - 1));
          timer = setTimeout(animateText, 50); // Faster deletion speed
        }
      }
    };

    timer = setTimeout(animateText, 100);

    return () => clearTimeout(timer);
  }, [displayText, isDeleting, wordIndex, words]);

  return (
    <div className="min-h-screen  text-gray-900" style={{background:theme.palette.sidebar.background, color:theme.palette.sidebar.text}}>
      {/* Navigation */}
      <nav className="flex justify-between items-center p-6" style={{background:theme.palette.sidebar.background}}>
        <div className="text-2xl font-bold">Patent</div>
        <button className="bg-black text-white px-6 py-2 rounded-full hover:bg-gray-800 transition-colors"
        onClick={() => navigate('/chat')}
        >
          Try Now
        </button>
      </nav>

      {/* Hero Section */}
      <main className="container mx-auto px-4 text-center mt-20">
        <h1 className="text-5xl md:text-6xl font-serif mb-4 max-w-4xl mx-auto">
          AI Patent Search Tool For{" "}
          <div className="inline-flex justify-center relative">
            <span className="inline-block min-w-[280px] text-center">
              {displayText}
              <span className="animate-pulse ml-1 border-r-4 border-black h-12 inline-block align-middle"></span>
            </span>
          </div>
        </h1>

        <p className="text-xl md:text-2xl mb-8 max-w-2xl mx-auto">
          Secure & Comprehensive Patent Search Without Keywords
        </p>

        <button 
        style={{background: theme.palette.rightPanel.background, color:theme.palette.rightPanel.text}}
        className="px-8 py-3 text-lg border-2 border-black rounded-full hover:bg-black hover:text-white transition-colors mb-16">
          Schedule Appointment →
        </button>

        {/* Features */}
        <div className="flex flex-wrap justify-center gap-8 mt-16 text-lg">
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 bg-black rounded-full"></div>
            <span>AI-Powered</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 bg-black rounded-full"></div>
            <span>Vetted by Top IP Firms</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 bg-black rounded-full"></div>
            <span>Backed by MIT VMS</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 bg-black rounded-full"></div>
            <span>Trusted by Leading Academic Institutions</span>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LandingPage;
