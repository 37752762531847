import { Typography } from "@mui/material";

export const Properties = ({
  keyString,
  value,
}: {
  keyString: string;
  value: string;
}) => (
  <Typography
    variant="subtitle1"
    fontSize={"12px"}
    fontWeight={"bold"} marginTop={"0px !important"}
    component="span" 
  >
    {`${keyString} :`}
    <Typography variant="subtitle1" fontSize={"12px"} component="span">
      {value}
    </Typography>
  </Typography>
);
