import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#333',
      },
      secondary: {
        main: '#F3F2EC',
      },
      sidebar: {
        background: 'rgb(229 228 222)',
        text: '#333',
      },
      rightPanel: {
        background: '#F3F2EC',
        text: '#000',
      },
      Customlight1:{
        background: 'rgb(229 228 222)',
        text: '#ddd',
      },
      searchInput:{
        background: '#F3F2EC',
        text: '#000',
      },
      button:{
        background: 'rgb(229 228 222)',
        text: 'gray',
      }
    },
  });
  
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#ececec',
      },
      secondary: {
        main: '#333',
      },
      mainText:"#ececec",
    //   secondaryText:'',
    //   lightText:"",
      sidebar: {
        background: '#171717',
        text: '#ececec',
      },
      rightPanel: {
        background: '#212121',
        text: '#ddd',
      },
      Customlight1:{
        background: '#2F2F2F',
        text: '#ddd',
      },
      searchInput:{
        background: '#2F2F2F',
        text: '#ececec',
      },
      button:{
        background: 'lightgray',
        text: 'gray',
      }
    },
  });
  

export { lightTheme, darkTheme };
