import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import startImg from "../assets/icons/start.svg";
import { AccountCircle, PlayCircle } from "@mui/icons-material";
import resultLogo from "../assets/resultlogo.png";
import Whitelogo from "../assets/whiteLongLogo.png";
import blacklogo from "../assets/blackLongLogo.png";

export default function ChatWithPatent({ currentUserId, recipientId }: any) {
  const [messages, setMessages] = useState<any>([]);
  const [newMessage, setNewMessage] = useState("");
  const theme = useTheme()

  const sendMessage = async (messageData: any) => {
    // Send message to your server or database
    // Replace with actual API call
  };
  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const messageData = {
        senderId: currentUserId,
        message: newMessage,
        timestamp: new Date().toISOString(),
      };
      const replyData = {
        receiverId: recipientId,
        reply: "Loreum Ipsome do",
        timestamp: new Date().toISOString(),
      };
      // await sendMessage([messageData, replyData]);
      setMessages((prevMessages: any) => [
        ...prevMessages,
        messageData,
        replyData,
      ]);
      setNewMessage("");
    }
  };

  return (
    <div className="chat-container">
      <div className="messages">
        {messages?.length > 0 &&
          messages.map((msg: any, index: any) => {
            return (
              <>
                {msg.senderId === currentUserId ? (
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent={"space-between"}
                      alignItems="end"
                      className="receive-message"
                    >
                      <Stack direction="row">
                        <AccountCircle />
                        <Typography
                          fontWeight={"bold"}
                          style={{ marginLeft: "10px", marginTop: "2px" }}
                        >
                          {"You"}
                        </Typography>
                      </Stack>
                      {/* <div style={{ marginLeft: "10px" }}>{new Date(msg.timestamp).toLocaleTimeString()}</div> */}
                    </Stack>
                    <div style={{}}>{msg.message}</div>
                    <div style={{ fontWeight: "normal", fontSize: 16 }}>
                      {msg.reply}
                    </div>
                  </Stack>
                ) : (
                  <Stack key={index} className={"sent-message"} sx={{marginBottom:'5px'}}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                    <img
                    src={theme.palette.mode === "dark" ? Whitelogo : blacklogo}
                    alt="Logo"
                    style={{ height: 20, width: 80 }}
                  />

                      {/* <small>{new Date(msg.timestamp).toLocaleTimeString()}</small> */}
                    </Stack>
                    <div style={{ fontWeight: "normal", fontSize: 16 }}>
                      {msg.reply}
                    </div>
                  </Stack>
                )}
              </>
            );
          })}
      </div>
      <Box
      // className={"search-input-component"}
      // sx={{ paddingRight: "205px", bottom: "25px" }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "25px",
            padding: "12px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #d6d5d5",
          }}
        >
          <input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder={"Type your message"}
            style={{
              width: "85%",
              borderWidth: 0,
              border: "none",
              outline: "none",
              color:'black'
            }}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
          <Button
            onClick={() => handleSendMessage()}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.button?.background,
              borderRadius: "22px",
              "&:hover": {
                backgroundColor: theme.palette.sidebar?.background,
                color:theme.palette.sidebar?.text // Darker shade for hover effect
              },
              color:theme.palette.button?.text,
              fontWeight:'600',
              textTransform: "none", // Prevents text from being uppercased
              padding: "8px 16px", // Adjust padding if needed
              columnGap: "8px",
              textWrap: "nowrap",
            }}
          >
            {'Send'} <PlayCircle/>
          </Button>
        </Box>
      </Box>
    </div>
  );
}
