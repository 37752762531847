import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import apiEndPoints from "../utils/apiEndPoints";
import { apiCall } from "../utils/httpClient";
import { GLOBAL_URL } from "../utils/constants";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

interface ProjectModalProps {
  open: boolean;
  handleClose: any;
  GetProject: () => void;
  editEmail: any;
  setEditEmail: any;
}

interface Email {
  id: number;
  email: string;
}

const contentStyle = {
  maxHeight: "60vh", // Set a maximum height for scrolling
  overflowY: "auto", // Enable vertical scrolling
};

const ProjectModal: React.FC<ProjectModalProps> = ({
  open,
  handleClose,
  GetProject,
  editEmail,
  setEditEmail,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    width: isMobile ? "80%" : 450,
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 450,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [emails, setEmails] = useState<Email[]>([{ id: 1, email: "" }]);
  const [projectName, setProjectName] = useState<string>("");
  const [userId, setUserId] = useState(localStorage.getItem("user_id"));
  const [userEmail, setUserEmail] = useState();
  const [projectNameError, setProjectNameError] = useState<string>("");
  const [sameEmails, setSameEmails] = useState<Boolean>(false);

  useEffect(() => {
    const user: any = JSON.parse(localStorage.getItem("user") || "null");
    if (user) {
      setUserEmail(user.email);
      setUserId(user._id);

      if (editEmail?.open) {
        setSameEmails(false);
        setProjectName(editEmail?.editData?.projectName);
        setEmails(editEmail?.editData?.sharedEmails);
        const newEditEmails =
          editEmail?.editData?.sharedEmails?.length > 0
            ? editEmail?.editData?.sharedEmails?.map((itm: any) => {
                return {
                  email: itm,
                };
              })
            : [];
        setEmails(newEditEmails);
      } else {
        setProjectName("");
        setEmails([]);
      }
    }
  }, [editEmail.open]);

  const addEmail = () => {
    setEmails([...emails, { id: emails.length + 1, email: "" }]);
  };

  const removeEmail = (index: any) => {
    const newEmail = [...emails];
    newEmail.splice(index, 1);
    setEmails(newEmail);
  };

  const handleEmailChange = (txt: any, index: any, value: string) => {
    const updatedEmails: any = [...emails];
    // const getIndex = emails.findIndex((itm: any) => itm.email === txt);
    const newObj = { email: value };
    if (index >= 0) {
      updatedEmails[index] = newObj;
    } else {
      updatedEmails.push(newObj);
    }
    setEmails(updatedEmails);
  };

  const validateForm = () => {
    if (projectName.trim() === "") {
      setProjectNameError("Project name is required");
      return false;
    }
    setProjectNameError("");
    return true;
  };

  const handleSubmit = async (e: any) => {
    if (!validateForm()) return;

    const emailId = emails.map((em) => em.email);
    const projectData = {
      createdBy: userEmail,
      user_id: userId,
      projectName: projectName,
      sharedEmails: emailId,
    };

    try {
      const response = await fetch(`${GLOBAL_URL}/api/project/createProject`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(projectData),
      });
      if (response.ok) {
        const data = await response.json();
        toast.success(data?.message);
        setProjectName("");
        setEmails([]);
      } else {
        console.error(
          "Failed to create project:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error creating project:", error);
      toast.error("failed to create project");
    }
    GetProject();
    handleClose();
  };

  const handleEdit = async (e: any) => {
    const emailId = emails.map((em) => em.email);
    const checkUnique: any = emailId.filter(
      (item: any, index: any) => emailId.indexOf(item) === index
    );
    if (emailId?.length == checkUnique?.length || !Array.isArray(checkUnique)) {
      const projectData = {
        projectId: editEmail?.editData?.projectId,
        projectName: projectName,
        sharedEmails: emailId,
      };

      try {
        const response = await fetch(
          `${GLOBAL_URL}/api/project/updateProject`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(projectData),
          }
        );
        if (response.ok) {
          const data = await response.json();
          toast.success(data?.message);
          GetProject();
          // setUserId("")
        } else {
          console.error(
            "Failed to create project:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error creating project:", error);
        toast.error("Failed to create project");
      }
      GetProject();
      handleClose();
    } else {
      setSameEmails(true);
    }
  };

  return (
    <Modal
      aria-labelledby="project-modal-title"
      aria-describedby="project-modal-description"
      open={open || editEmail?.open}
      // onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open || editEmail?.open}>
        <Box sx={style}>
          <Typography id="project-modal-title" variant="h6" component="h2">
            {editEmail?.open ? "Edit Project" : "Add Project"}
          </Typography>
          <Box sx={contentStyle}>
            {/* <TextField
              label="Project Name"
              fullWidth
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              margin="normal"
              variant="standard"
              InputProps={{
                sx: {
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderRadius: 0,
                },
              }}
            /> */}
            <TextField
              label="Project Name"
              fullWidth
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              margin="normal"
              variant="standard"
              error={!!projectNameError}
              helperText={projectNameError}
              InputProps={{
                sx: {
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderRadius: 0,
                },
              }}
            />
            {emails.map((email, index) => {
              return (
                <Box key={email.id} sx={{ mb: 2 }}>
                  <TextField
                    label={`Email Address ${index + 1}`}
                    fullWidth
                    value={email.email}
                    onChange={(e) =>
                      handleEmailChange(email.email, index, e.target.value)
                    }
                    margin="normal"
                    variant="standard"
                    InputProps={{
                      onChange: (txt: any) => {
                        handleEmailChange(email.email, index, txt.target.value);
                      },
                      sx: {
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderRadius: 0,
                        borderTopColor: "white",
                        outlineColor: "white",
                      },
                    }}
                  />
                  <Button onClick={() => removeEmail(index)} sx={{ mt: 1 }}>
                    Remove Email
                  </Button>
                </Box>
              );
            })}
            <Button
              onClick={addEmail}
              sx={{
                textDecoration: "underline",
                fontSize: "12px",
                paddingLeft: 0,
              }}
            >
              Add Team Member's Email Address
            </Button>
          </Box>
          {/* {sameEmails && <div style={{}}>Please Enter Different Emails</div>} */}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
                setProjectName("");
                setProjectNameError("");
                setEmails([]);
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              // onClick={handleSubmit}
              onClick={editEmail?.open ? handleEdit : handleSubmit}
            >
              {editEmail?.open ? "Save" : "Submit"}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ProjectModal;
