import React, { useState } from "react";
import "./responsiveHeader.css";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import leftLayout from "../assets/icons/layout-sidebar.svg";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import Whitelogo from "../assets/whiteLongLogo.png";
import blacklogo from "../assets/blackLongLogo.png";

const Header = ({ isMobile, isAuth, toggleDrawer, handleLogout }: any) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); 
  const navigate = useNavigate();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); 
  };

  const handleMenuClose = () => {
    setAnchorEl(null); 
  };

  return (
    <header
      className="header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "25px 0",
        background:theme.palette.rightPanel?.background
      }}
    >
      {/* Toggle Icon */}

        <Box sx={{ height: "100%" }}>
          <Button sx={{}} onClick={toggleDrawer(true)}>
            <img src={leftLayout} alt="left-Layout" style={{width:'24px'}} /> 
          </Button>
        </Box>
    

      <Box className="image-container">
        <img
          src={theme.palette.mode === 'dark' ?  Whitelogo : blacklogo}
          style={{ cursor: "pointer", width: "110px" }}
          onClick={() => navigate("/chat")}
        />
      </Box>

      {isAuth ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: "10px",
          }}
        >
          <IconButton onClick={handleMenuClick}>
            <MoreVertSharpIcon style={{ fontSize: "24px", color: '#7f7f7f' }} />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              sx={{ display: "flex", flexDirection: "column" }}
              onClick={() => {
                navigate("/");
              }}
            >
              <Typography onClick={() => navigate("/plans")}>
                Home
              </Typography>
            </MenuItem>
            <MenuItem
              sx={{ display: "flex", flexDirection: "column" }}
              onClick={() => {
                navigate("/plans");
              }}
            >
              <Typography textAlign="center" onClick={() => navigate("/plans")}>
                Upgrade
              </Typography>
            </MenuItem>
            <MenuItem
              sx={{ display: "flex", flexDirection: "column" }}
              onClick={() => {
                handleLogout();
              }}
            >
              <Typography textAlign="center" style={{ width: "100%" }}>
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <Box
        style={{
          display: "flex",
          alignItems: "center",
          paddingRight: "10px",
        }}
      >
        <IconButton onClick={handleMenuClick}>
          <MoreVertSharpIcon style={{ fontSize: "24px", color: '#7f7f7f' }} />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            sx={{ display: "flex", flexDirection: "column" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Typography onClick={() => navigate("/plans")}>
              Home
            </Typography>
          </MenuItem>
          <MenuItem
            sx={{ display: "flex", flexDirection: "column" }}
            onClick={() => {
              navigate("/plans");
            }}
          >
            <Typography textAlign="center" onClick={() => navigate("/plans")}>
              Upgrade
            </Typography>
          </MenuItem>
          <MenuItem
            sx={{ display: "flex", flexDirection: "column" }}
            onClick={() => {
              navigate('/login');
            }}
          >
            <Typography textAlign="center" style={{ width: "100%" }}>
              Login
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
      )}
    </header>
  );
};

export default Header;
