import ReactGA from 'react-ga4';

const TRACKING_ID = "G-BG6W0YZE74"; // Your GA4 Measurement ID
// ReactGA.initialize(TRACKING_ID, { testMode: true }); // Enable testMode for local testing

ReactGA.initialize(TRACKING_ID, { debug: false });

// Function to track page views
export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Function to track custom events
export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
