import {
  CssBaseline,
  Typography,
  Container,
  Box,
  Grid,
  Button,
  TextField,
  Alert,
  useTheme,
} from "@mui/material";
import "react-notifications/lib/notifications.css";

import { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { GLOBAL_URL } from "../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { backgroundColor } from "../assets/colors";
import logo from "../assets/logo.png";
import toast from "react-hot-toast";
import Whitelogo from "../assets/whiteLongLogo.png";
import blacklogo from "../assets/blackLongLogo.png";

const Register = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const topdiv = {
    height: "100vh",
    padding: "10px",
    background: theme.palette.rightPanel?.background,
  };

  const submitData = (data: any) => {
    setIsLoading(true);
    let params = {
      action: "signup",
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      password: data.password,
      confirm_password: data.cpassword,
    };
    axios
      .post(`${GLOBAL_URL}/api/signup`, params, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status == 201) {
          toast.success("Registration successfull!");
          if (id) {
            navigate(`/login?id=${id}`);
          } else {
            navigate(`/login`);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error("Registration failed. Please try again. Error");
        }
      })
      .catch(function (error) {
        toast.error("Registration failed. Please try again. Error");
      })
      .finally(function () {
        setIsLoading(false); // Re-enable button
      });
  };
  return (
    <div style={topdiv}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: theme.palette.sidebar?.background,
            borderRadius: "30px",
            padding: "30px",
            
          }}
        >
          <img
            src={theme.palette.mode === "dark" ? Whitelogo : blacklogo}
            style={{ cursor: "pointer", width: "180px", marginBottom: "20px" }}
            onClick={() => navigate("chat/")}
          />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form autoComplete="off">
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="First Name"
                    autoFocus
                    autoComplete="fname"
                    variant="outlined"
                    id="firstName"
                    {...register("firstname", {
                      required: "First name is required!",
                    })}
                    value={firstname}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      clearErrors("firstname");
                      setValue("firstname", e.target.value);
                    }}
                    error={!!errors.firstname}
                    helperText={errors.firstname?.message as any}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    variant="outlined"
                    autoComplete="lname"
                    {...register("lastname", {
                      required: "Last name is required!",
                    })}
                    value={lastname}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      clearErrors("lastname");
                      setValue("lastname", e.target.value);
                    }}
                    error={!!errors.lastname}
                    helperText={errors.lastname?.message as any}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    id="email"
                    type="email"
                    label="Email Address"
                    {...register("email", {
                      required: "Email is required!",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address!",
                      },
                    })}
                    autoComplete="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      clearErrors("email");
                      setValue("email", e.target.value);
                    }}
                    error={!!errors.email}
                    helperText={errors.email?.message as any}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    {...register("password", {
                      required: "Password is required!",
                    })}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      clearErrors("password");
                      setValue("password", e.target.value);
                    }}
                    error={!!errors.password}
                    helperText={errors.password?.message as any}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    id="cpassword"
                    autoComplete="current-password"
                    {...register("cpassword", {
                      required: "Confirm Password is required!",
                      validate: (value) =>
                        value === watch("password") || "Passwords must match!",
                    })}
                    error={!!errors.cpassword}
                    helperText={errors.cpassword?.message as any}
                    value={cpassword}
                    onChange={(e) => {
                      setCPassword(e.target.value);
                      clearErrors("cpassword");
                      setValue("cpassword", e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              {isLoading ? (
                <Button
                  type="button"
                  fullWidth
                  id="submit"
                  variant="contained"
                  disableRipple // Disable click effect
                  sx={{
                    mt: 3,
                    mb: 2,
                    // backgroundColor: "gray", // Maintain background color
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "gray", // Prevent hover effect
                      boxShadow: "none", // Remove shadow on hover
                    },
                    "&:active": {
                      boxShadow: "none", // Remove shadow on click
                    },
                    cursor: "not-allowed",
                  }}
                  disabled={true}
                >
                  {/* <LoadingScreen /> */}
                  Loading...
                </Button>
              ) : (
                <Button
                  type="button"
                  fullWidth
                  id="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSubmit(submitData)}
                  disabled={isLoading}
                >
                  Sign Up
                </Button>
              )}
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    onClick={() => navigate("/login")}
                    variant="text"
                    sx={{
                      textTransform: "none",
                      textDecoration: "underline",
                      "&:hover": {
                        textDecoration: "underline", // Keep underline on hover
                      },
                    }}
                  >
                    Already have an account? Sign in
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    onClick={() => navigate("/")}
                    variant="text"
                    sx={{
                      textTransform: "none",
                      textDecoration: "underline",
                      "&:hover": {
                        textDecoration: "underline", // Keep underline on hover
                      },
                    }}
                  >
                    Home
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Container>
    </div>
  );
};

export default Register;
