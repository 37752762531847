import axios from "axios";
import { GLOBAL_URL } from "./constants";
import EndPoints from "./apiEndPoints";


const httpClient = axios.create({
  baseURL: `${GLOBAL_URL}/api/`,
});

export function updateLocalstorage(name, key, value) {
  var DashboardData = localStorage.getItem(name);
  DashboardData = DashboardData ? JSON.parse(DashboardData) : {};
  DashboardData[key] = value;
  localStorage.setItem(name, JSON.stringify(DashboardData));
}

export function setDefaultHeader(header, value) {
  httpClient.defaults.headers.common = {'Authorization': `Bearer ${value}`};

}

export function setDefaultHeaderval() {
  const authval = localStorage.getItem("AuthToken");
  if (authval) {
    setDefaultHeader("token", authval);
  } else {
    const tokenlocal = localStorage.getItem("token");
    if (tokenlocal) {
      setDefaultHeader("token", tokenlocal);
    } else {
      // getToken();
    }
  }
}

async function getToken() {
  const authval = await localStorage.getItem("userToken");
  try {
    const { data } = await apiCall("get", EndPoints.JWTTOKEN);
    if (data) {
      await setDefaultHeader("token", data.token);
    } else {
      //mm below code
      await setDefaultHeader("token", JSON.parse(authval).token);
    }
  } catch (error) {
    console.log(error);
  }
}

export async function apiCall(
  method,
  url,
  data,
  header = {
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
  }
) {
  try {    
    const authval = await localStorage.getItem("userToken");
    let headers = header;
    if (authval !== null) {
      headers = { ...header, 'x-auth-token': authval };
    }
    const response = await httpClient({
      method,
      url,
      data,
      headers: headers,
      withCredentials: false,
    });

    if (response.status === 200) {
      return response;
    }
    if (response.status === 201) {
      return response;
    }
  } catch (error) {
    
    if (error.response) {
      if (error.response.status === 401) {
        console.log(`${url}: `, error.response);
        return error.response;
      }
      console.log("Error data : ", error.response.data);
      console.log("Error status : ", error.response.status);
      console.log("Error headers : ", error.response.headers);
    } else if (error.request) {
      console.log("Error request : ", error.request);
    } else {
      console.log("Error message : ", error.message);
    }
    console.log("Error config", error.config);
    // console.log("errorresponse", error.response);
    console.log("Error", error);
    return false;
  }
}