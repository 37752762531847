// import React, { createContext, useState, useContext } from 'react';

// // Create the context
// const HistoryContext = createContext();

// // Create a provider component
// export const HistoryProvider = ({ children }) => {
//   const [searchHistory, setSearchHistory] = useState([])
  

//   return (
//     <HistoryContext.Provider value={{ searchHistory, setSearchHistory }}>
//       {children}
//     </HistoryContext.Provider>
//   );
// };

// // Custom hook for easier access to the context
// export const useSearchHistory = () => {
//   return useContext(HistoryContext);
// };

import React, { createContext, useState, useContext } from 'react';

// Create the context
const HistoryContext = createContext();

// Create a provider component
export const HistoryProvider = ({ children }) => {
  const [searchHistory, setSearchHistory] = useState([]);

  return (
    <HistoryContext.Provider value={{ searchHistory, setSearchHistory }}>
      {children}
    </HistoryContext.Provider>
  );
};

// Custom hook for easier access to the context
export const useSearchHistory = () => {
  return useContext(HistoryContext);
};

