import { Stack, useTheme, Typography } from "@mui/material";
import Whitelogo from "../assets/whiteLongLogo.png";
import blacklogo from "../assets/blackLongLogo.png";

const LoadingScreen = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        marginBottom:'77px',
        flexDirection:'column'
      }}
    >
         <Stack
        direction="row"
        spacing={1.5}
        justifyContent="start"
        alignItems="flex-end"
        sx={{ marginBottom: "15px" }}
      >
        <img
          src={theme.palette.mode === "dark" ? Whitelogo : blacklogo}
          alt="Logo"
          style={{ height: 20, width: 80 }}
        />
      </Stack>
      <div
        style={{
          display: "flex",
          gap: "5px",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.rightPanel?.text,
          }}
        >
          Scanning 6 million US Patents
        </Typography>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "1.2rem",
            lineHeight: 1.2,
            fontFamily: "sans-serif",
            maxWidth: "20rem",
            animation: "fadeIn 1s ease-in-out both",
            display: "flex",
            gap: "5px",
          }}
        >
          {Array.from({ length: 4 }).map((_, index) => (
            <span
              key={index}
              data-text="."
              style={{
                color: theme.palette.text.secondary,
                display: "inline-block",
                position: "relative",
                animation: `bounce 1.5s ease-in-out infinite`,
                animationDelay: `${index * 0.2}s`, // Delay for staggered bounce
              }}
            >
              .
            </span>
          ))}
          <style>
            {`
          @keyframes bounce {
            0%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-5px);
            }
          }
        `}
          </style>
        </div>
      </div>
   
    </div>
  );
};

export default LoadingScreen;
