export const backgroundColor = '#F3F2EC';
export const rightPanelColor = '#F3F2EC';
export const leftPnaleColor ='#F3F2EC';
export const chatBoxColor = 'white';
export const serviceDescriptionCards = 'white';
export const customBlack = '#101828'
export const lightGray = '#475467'

export const colorCodes = [
    '#98A2B3', '#1D2939',
    '#D6BBFB', '#6941C6',
    '#FDA29B', '#D92D20',
    '#6CE9A6', '#12B76A', '#05603A', '#054F31',
    
  ];