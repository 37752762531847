import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Login from "../screens/Login";
import Register from "../screens/Register";
import { GLOBAL_URL } from "../utils/constants";
import axios from "axios";
import { apiCall, setDefaultHeader } from "../utils/httpClient";
import Plans from "../screens/Plans";
import {
  NotificationManager,
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { BookmarkProvider } from "../context/BookmarkContext";
import { MessageProvider } from "../context/MessageContext";
import toast from "react-hot-toast";
import { HistoryProvider } from "../context/HistoryContext";
import Chat from "../screens/Chat";
import { darkTheme, lightTheme } from "../utils/muiTheme";
import { ThemeProvider } from "@mui/material";
import LandingPage from "screens/LandingPage";
import Contact from "screens/Contact";
import Book from "screens/Book";

const AppRoutes = () => {
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();
  const [Id, setId] = useState("");

  const [selectedProject, setSelectedProject] = useState(null);
  const [searchHistory, setSearchHistory] = useState([]);
  const [darkMode, setDarkMode] = useState(true);
  console.log("darkMode: ", darkMode);

  const toggleTheme = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      setIsAuth(true);
    }
  }, []);
  const submitData = (data) => {
    let params = {
      action: "user-login",
      email: data.email,
      password: data.password,
    };
    apiCall("post", GLOBAL_URL + "/api/login", params)
      .then(function (response) {
        if (response.status == 200) {
          const token = response.data.token;
          const user_id = response.data.data._id;
          toast.success("Logged in successfully");

          setDefaultHeader("token", token);
          localStorage.setItem("userToken", token);
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("user", JSON.stringify(response.data.data));

          setIsAuth(true);
          if (Id) {
            navigate(`/plans?id=${Id}`);
          } else {
            navigate("/chat");
          }
        } else if (response.status == 201) {
          toast.error(response?.data?.message);
        } else {
          NotificationManager.error("server error", "Error");
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Something went wrong. Try again.");
      });
  };
  const RedirectRoute = ({ isAuth, children }) => {
    return isAuth ? <Navigate to="/" /> : children;
  };

  const getHistory = async () => {
    const user_id = JSON.parse(localStorage.getItem("user") || "null")?._id;
    if (user_id) {
      const data = {
        project_id: selectedProject ?? "",
        user_id: user_id,
      };

      try {
        const response = await axios.post(
          `${GLOBAL_URL}/api/conversation/getSearchHistory`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response && response.data) {
          const fetchHistory = response.data.data;
          setSearchHistory(fetchHistory);
        } else {
          console.error("Failed to retrieve History.");
        }
      } catch (error) {
        console.error("Error rrr in History function:", error);
      }
    }
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <HistoryProvider>
        <MessageProvider>
          <BookmarkProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <RedirectRoute>
                    <LandingPage />
                  </RedirectRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <RedirectRoute isAuth={isAuth}>
                    <Login submitData={submitData} setId={setId} />
                  </RedirectRoute>
                }
              />
              <Route
                path="/register"
                element={
                  <RedirectRoute isAuth={isAuth}>
                    <Register />
                  </RedirectRoute>
                }
              />

              <Route
                path="/chat"
                element={
                  <Chat
                    isAuth={isAuth}
                    setIsAuth={setIsAuth}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    getHistory={getHistory}
                    searchHistory={searchHistory}
                    setSearchHistory={setSearchHistory}
                    darkMode={darkMode}
                    setDarkMode={setDarkMode}
                  />
                }
              />
              <Route
                path="/search/:conversation_id"
                element={
                  <Chat
                    isAuth={isAuth}
                    setIsAuth={setIsAuth}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    getHistory={getHistory}
                    searchHistory={searchHistory}
                    setSearchHistory={setSearchHistory}
                    darkMode={darkMode}
                    setDarkMode={setDarkMode}
                  />
                }
              />
              <Route
                path="/plans"
                element={
                  <Plans
                    isAuth={isAuth}
                    setIsAuth={setIsAuth}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    searchHistory={searchHistory}
                    setSearchHistory={setSearchHistory}
                    getHistory={getHistory}
                    darkMode={darkMode}
                    setDarkMode={setDarkMode}
                  />
                }
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/book" element={<Book />} />
            </Routes>
          </BookmarkProvider>
        </MessageProvider>
      </HistoryProvider>
    </ThemeProvider>
  );
};

export default AppRoutes;
