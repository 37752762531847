import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  GlobalStyles,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  backgroundColor,
  rightPanelColor,
  leftPnaleColor,
} from "./assets/colors";
import { LeftPanel } from "./components/left-panel";
import { RightPanel } from "./components/right-panel";
import Header from "./components/Header";
import SideMobileDrawer from "./components/responsive-left-panel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { GLOBAL_URL } from "./utils/constants";
import axios from "axios";
import { useBookmark } from "./context/BookmarkContext";

import Sidebar from "./components/sidebar";
import { trackPageView } from "./utils/analytics";

// Step 1: Create a theme extending the default theme
const themes = createTheme({
  palette: {
    primary: {
      main: "#CB6CE6", // Your custom primary color
    },
  },
  typography: {
    fontFamily: `'Inter', sans-serif`, // Global font family
  },
});

function App({
  isAuth,
  selectedProject,
  setSelectedProject,
  getHistory,
  searchHistory,
  setSearchHistory,
  children,
  setDarkMode,
  darkMode
}: any) {
  const theme = useTheme();
  console.log('theme: ', theme);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [openTemp, setOpenTemp] = React.useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [logoutNotification, setLogoutNotification] = useState<any>();
  const { setBookMark } = useBookmark();
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setOpen(false);
    } else {
      setOpen(false);
    }
  }, [isMobile]);

  // useEffect(() => {
  //   getBookmark();
  // }, [selectedProject]);

  const togglePanel = () => {
    setOpen(!open);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenTemp(newOpen);
  };

  const GlobalStyle = () => {
    return (
      <GlobalStyles
        styles={{
          /* For Webkit browsers (Chrome, Safari) */
          "*::-webkit-scrollbar": {
            width: "6px", // Thin scrollbar width
            height: "6px", // Thin scrollbar height for horizontal scroll
          },
          "*::-webkit-scrollbar-thumb": {
            // backgroundColor: "#CB6CE6", // Scrollbar thumb color (customized here)
            borderRadius: "10px", // Rounded corners for scrollbar
          },
          "*::-webkit-scrollbar-track": {
            // backgroundColor: "#f0f0f0", // Scrollbar track color
          },

          /* For Firefox (using scrollbar-width property) */
          "*": {
            scrollbarWidth: "thin", // Firefox equivalent of thin scrollbar
            // scrollbarColor: "#CB6CE6 #f0f0f0", // Thumb color and track color for Firefox
          },
        }}
      />
    );
  };

  const getNotification = async () => {
    const user_id = await localStorage.getItem("user_id");
    const params = {
      user_id: user_id,
    };
    try {
      const response = await fetch(`${GLOBAL_URL}/api/notification`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      });
      const responseData = await response.json();
      setNotificationData(responseData.notifications);
    } catch (error) {
      console.log(error, "====>");
    }
  };

  // const getBookmark = async () => {
  //   const user_id = await localStorage.getItem("user_id");
  //   if (user_id) {
  //     const bookmarkData = {
  //       user_id: user_id,
  //       projectId: selectedProject,
  //     };

  //     try {
  //       const response = await axios.post(
  //         `${GLOBAL_URL}/api/bookmark/getBookmark`,
  //         bookmarkData,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response && response.data) {
  //         const fetchedBookmarks = response.data;
  //         setBookMark(fetchedBookmarks);
  //       } else {
  //         console.error("Failed to retrieve bookmarks.");
  //       }
  //     } catch (error) {
  //       console.error("Error in getBookmark function:", error);
  //     }
  //   }
  // };

  return (
    // <ThemeProvider theme={themes}>
    //   <CssBaseline />
    <Box sx={{ display: "flex" }}>
      <Stack
        direction="row"
        width="100%"
        height="100%"
        style={{ minHeight: "100vh", background: theme?.palette?.sidebar?.background }}
        justifyContent="center"
        position="relative"
        sx={{ marginTop: "-30px" }}
      >
        {/* {isAuth && ( */}
        <Stack
          style={{
            width: open ? "27%" : "0%",
            height: "100vh",
            border: "none",
            background: theme?.palette?.sidebar?.background,
            transition: "width 0.2s ease",
            paddingBottom: "55px",
            // overflow: "auto",
            minHeight: "100%",
            marginTop: "65px",
          }}
          // sx={{
          //   "&::-webkit-scrollbar": {
          //     display: "none", // For Chrome, Safari, and Edge
          //   },
          // }}
        >
          {open ? (
            <LeftPanel
              sidebarClose={togglePanel}
              setOpenTemp={setOpenTemp}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              getHistory={getHistory}
              searchHistory={searchHistory}
              setSearchHistory={setSearchHistory}
              // getBookmark={getBookmark}
            />
          ) : (
            !isMobile && <Sidebar sidebarOpen={togglePanel} />
          )}
        </Stack>
        {/* )} */}
        <Stack
          style={{
            width: open ? "73%" : isMobile ? "100%" : "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "end",
            background: theme.palette.rightPanel?.background,
            transition: "width 0.2s ease",
            paddingTop: 42,
          }}
        >
          <Header
            toggleDrawer={toggleDrawer}
            isAuth={isAuth}
            notificationData={notificationData}
            logoutNotification={logoutNotification}
            setLogoutNotification={setLogoutNotification}
            darkMode={darkMode}
            setDarkMode={setDarkMode}
          />
          {/* children == > Chat Screen / Plan Screen */}
          {children}
        </Stack>
      </Stack>
      {isMobile && (
        <SideMobileDrawer
          openTemp={openTemp}
          setOpenTemp={setOpenTemp}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          getHistory={getHistory}
          searchHistory={searchHistory}
          setSearchHistory={setSearchHistory}
          // getBookmark={getBookmark}
        />
      )}
    </Box>
    // </ThemeProvider>
  );
}

export default App;
