import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { backgroundColor } from "../assets/colors";
import { Badge, IconButton } from "@mui/material";
import logo from "../assets/logo.png";
import leftLayout from "../assets/icons/layout-sidebar.svg";
import NotificationsIcon from "../assets/icons/notification.svg";
import { useNavigate } from "react-router-dom";
import ResponsiveHeader from "./ResponsiveHeader";
import axios from "axios";
import { CheckCircle, NotificationsOutlined } from "@mui/icons-material";

const Header = ({
  toggleDrawer,
  isAuth,
  notificationData,
  setLogoutNotification,
  logoutNotification,
  darkMode,
  setDarkMode,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const notifications = 5;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [showNotification, setshowNotification] = useState(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = async () => {
    await localStorage.removeItem("userToken");
    await localStorage.removeItem("user_id");
    await localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };
  const userDataString = localStorage.getItem("user");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const name = userData ? userData.first_name : null;

  const toggleTheme = () => {
    setDarkMode((prevMode: any) => {
      const newMode: any = !prevMode;
      localStorage.setItem("darkMode", newMode);
      return newMode;
    });
  };

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);
  // const name =  null;
  return (
    <Stack direction="row">
      {isMobile ? (
        <ResponsiveHeader
          isMobile={isMobile}
          isAuth={isAuth}
          toggleDrawer={toggleDrawer}
          handleLogout={handleLogout}
        />
      ) : (
        <Stack direction={"row"} alignItems={"center"} paddingRight={"20px"}>
          <Box sx={{ p: 2 }}>
            <Switch checked={darkMode} onChange={toggleTheme} />
          </Box>
          <IconButton
            aria-label="show new notifications"
            color="inherit"
            onClick={() => setshowNotification(!showNotification)}
            onBlur={() => setshowNotification(false)}
            sx={{
              marginRight: "20px",
              color: theme.palette.rightPanel?.text,
              fontSize: "30px",
              background: theme.palette.Customlight1?.background,
            }}
          >
            {/* <img src={NotificationsIcon} style={{ width: "25px". }} /> */}
            <NotificationsOutlined />
            {(notificationData?.length > 0 || logoutNotification > -1) && (
              <Box
                sx={{
                  width: "13px",
                  height: "13px",
                  backgroundColor: "#F97066",
                  borderRadius: "50%",
                  position: "absolute",
                  top: 4,
                  right: 6,
                  border: "2px solid white",
                  marginBottom: "25px",
                }}
              ></Box>
            )}
          </IconButton>
          {isAuth && showNotification && notificationData?.length > 0 ? (
            <Box
              sx={{
                backgroundColor: "white",
                width: 300,
                maxHeight: 300,
                position: "absolute",
                top: "82px",
                right: "85px",
                zIndex: 9,
                padding: 2,
                overflowY: "scroll",
                borderRadius: 5,
              }}
            >
              <Typography sx={{ margin: 1 }} fontWeight={600}>
                Notifications
              </Typography>
              {notificationData?.length > 0 &&
                notificationData.map((data: any) => (
                  <Paper
                    elevation={2}
                    sx={{
                      padding: 2,
                      display: "flex",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <CheckCircle color="primary" sx={{ mr: 1 }} />
                    <Box>
                      <Typography variant="caption">{data.title}</Typography>
                    </Box>
                  </Paper>
                ))}
            </Box>
          ) : (
            isAuth &&
            showNotification && (
              <Box
                sx={{
                  backgroundColor: "white",
                  width: 250,
                  maxHeight: 300,
                  position: "absolute",
                  top: "110px",
                  right: "85px",
                  zIndex: 9,
                  padding: 2,
                  // overflowY: "scroll",
                  borderRadius: 5,
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: 1 }} fontWeight={600}>
                  No Notifications
                </Typography>
              </Box>
            )
          )}
          {!isAuth && showNotification && logoutNotification > -1 ? (
            <Box
              sx={{
                backgroundColor: "white",
                width: 300,
                maxHeight: 300,
                position: "absolute",
                top: "88px",
                right: "85px",
                zIndex: 9,
                padding: 2,
                overflowY: "scroll",
                borderRadius: 5,
              }}
            >
              <Typography sx={{ margin: 1 }} fontWeight={600}>
                Notifications
              </Typography>
              {logoutNotification > -1 && (
                <Paper
                  elevation={2}
                  sx={{
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <CheckCircle color="primary" sx={{ mr: 1 }} />
                  <Box>
                    <Typography variant="caption">{`You have ${logoutNotification} credits remaining`}</Typography>
                  </Box>
                </Paper>
              )}
            </Box>
          ) : (
            !isAuth &&
            showNotification && (
              <Box
                sx={{
                  backgroundColor: "white",
                  width: 250,
                  maxHeight: 300,
                  position: "absolute",
                  top: "110px",
                  right: "85px",
                  zIndex: 9,
                  padding: 2,
                  borderRadius: 5,
                  textAlign: "center",
                }}
              >
                <Typography sx={{ margin: 1 }} fontWeight={600}>
                  No Notifications
                </Typography>
              </Box>
            )
          )}
          {isAuth && (
            <>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu}>
                  <Avatar
                    sx={{
                      width: 38,
                      height: 38,
                      backgroundColor: "black",
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {name ? name.charAt(0).toUpperCase() : ""}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "49px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                style={{ borderRadius: "10px" }}
              >
                <MenuItem
                  sx={{ display: "flex", flexDirection: "column" }}
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate("/");
                  }}
                >
                  <Typography
                    textAlign="center"
                    onClick={() => navigate("/plans")}
                  >
                    Home
                  </Typography>
                </MenuItem>
                <MenuItem
                  sx={{ display: "flex", flexDirection: "column" }}
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate("/plans");
                  }}
                >
                  <Typography
                    textAlign="center"
                    onClick={() => navigate("/plans")}
                  >
                    UPGRADE
                  </Typography>
                </MenuItem>
                <MenuItem
                  sx={{ display: "flex", flexDirection: "column" }}
                  onClick={() => {
                    handleCloseUserMenu();
                    handleLogout();
                  }}
                >
                  <Typography textAlign="center" style={{ width: "100%" }}>
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Stack>
      )}

      {/* LoggedOut in web window  */}
      {!isAuth && !isMobile && (
        <Box alignItems={"center"} paddingRight={"10px"} display={"flex"}>
          <Button
            onClick={() => navigate("/plans")}
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: 3,
              padding: 1.1,
              marginRight: 2,
              fontWeight: 600,
            }}
          >
            UPGRADE
          </Button>
          <Button
            onClick={() => navigate("/login")}
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: 3,
              padding: 1.1,
              marginRight: 2,
              fontWeight: 600,
            }}
          >
            SIGN IN
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default Header;
