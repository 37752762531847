// SearchInput.tsx
import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import pinImg from "../assets/icons/pin.svg";
import startImg from "../assets/icons/start.svg";
import { GLOBAL_URL } from "../utils/constants";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { PlayCircle } from "@mui/icons-material";

interface SearchInputProps {
  inputText: string;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
  isMobile: boolean;
  placeHolder: string;
  buttonLabel: string;
  showAttachFileIcon: boolean;
  btnDisable: boolean;
  messages?:any;
  opensidebar?:any;
  isMobileResponsive?:any
  openmodalstate?:any
  isAuth?:any
  scrollToBottom?:any
}

const SearchInput: React.FC<SearchInputProps> = ({
  inputText,
  setInputText,
  onSubmit,
  isMobile,
  placeHolder,
  buttonLabel,
  showAttachFileIcon,
  btnDisable,
  messages,
  opensidebar,
  isMobileResponsive,
  openmodalstate,
  isAuth,
  scrollToBottom
}) => {
  const theme = useTheme()
  const navigate = useNavigate();
  const { conversation_id } = useParams();
  const createConversationId = async () => {
    try {
      const response = await axios.get(
        `${GLOBAL_URL}/api/conversation/generateConversationId`
      );
      if (response.status === 200) {
        if (response?.data?.conversation_id) {
          navigate(`/search/${response?.data?.conversation_id}`);
        } else {
          alert("Error in creating conversation_id");
        }
      } else {
        console.log("error in generateConversationId");
      }
    } catch (error) {
      return { hasPlan: false, hasCredits: false };
    }
  };

  const handleSubmit = () => {
    if (!conversation_id) {
      createConversationId();
    }
    scrollToBottom()
    onSubmit();
  };
  return (
    <Box
      className={`${messages?.length > 0 ? "search-input-bottom" : ""}`}
      sx={{background:theme.palette.rightPanel?.background, zIndex:1}}
      // sx={{paddingRight: messages?.length > 0 && isMobileResponsive ? "55px" : messages?.length > 0 && !isAuth ? "265px" : opensidebar || isMobileResponsive ? '' : "75px", bottom:isAuth ?"" :"25px"}}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.searchInput?.background,
          borderRadius: "25px",
          padding: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #d6d5d5",
        }}
       
      >
        <input
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          className="search-input"
          placeholder={placeHolder}
          style={{
            padding: "5px",
            border: "none",
            outline: "none",
            width: "100%",
            background:theme.palette.searchInput?.background,
            color:theme.palette.searchInput?.text
          }}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              handleSubmit()
            }
        }}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* {showAttachFileIcon && (
          <Button
            sx={{
              borderRadius: "50%", // Fully rounded button
              width: "45px", // Equal width and height
              height: "45px",
              padding: 0, // Remove padding to ensure the image fits within the button
              minWidth: "auto", // Prevents default minimum width from being applied
              boxShadow: "none", // Optional: remove shadow
            }}
          >
            <img src={pinImg} alt="Pin" />
          </Button>
        )} */}
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            disabled={btnDisable}
            sx={{
              backgroundColor: theme.palette.button?.background,
              borderRadius: "22px",
              "&:hover": {
                backgroundColor: theme.palette.sidebar?.background,
                color:theme.palette.sidebar?.text // Darker shade for hover effect
              },
              color:theme.palette.button?.text,
              fontWeight:'600',
              textTransform: "none", // Prevents text from being uppercased
              padding: "8px 16px", // Adjust padding if needed
              columnGap: "8px",
              textWrap: "nowrap",
            }}
          >
            {!isMobile && buttonLabel} <PlayCircle/>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchInput;
