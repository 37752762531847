import React, { createContext, useState, useContext } from 'react';

// Create the context
const MessageContext = createContext();

// Create a provider component
export const MessageProvider = ({ children }) => {
  const [messagesContext, setMessageContext] = useState([]);

  return (
    <MessageContext.Provider value={{ messagesContext, setMessageContext }}>
      {children}
    </MessageContext.Provider>
  );
};

// Custom hook for easier access to the context
export const useContextMessages = () => {
  return useContext(MessageContext);
};
