import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { LeftPanel } from "./left-panel";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { IconButton, useTheme } from "@mui/material";

// Define the type for props
interface SideMobileDrawerProps {
  setOpenTemp: (open: boolean) => void; // Function to set the drawer open state
  openTemp: boolean; // Boolean to control if the drawer is open
  setSelectedProject: (open: boolean) => void;
  selectedProject: string;
  getHistory: any;
  searchHistory: any;
  setSearchHistory: any;
  // getBookmark:any
}

const SideMobileDrawer: React.FC<SideMobileDrawerProps> = ({
  setOpenTemp,
  openTemp,
  setSelectedProject,
  selectedProject,
  getHistory,
  searchHistory,
  setSearchHistory,
  // getBookmark
}) => {
  const theme = useTheme()

  return (
    <div style={{ background:theme.palette.sidebar?.background }}>
      <Drawer
        open={openTemp}
        onClose={() => setOpenTemp(false)}
        sx={{ minWidth: "365px"}}
      >
        <Box role="presentation" sx={{ background:theme.palette.sidebar?.background, minHeight:'100vh' }}>
          <Box
            sx={{
              cursor: "pointer",
              textAlign: "end",
              paddingRight: "28px",
              paddingTop: "10px",
              position: "absolute",
              top: "40px",
              right: "10px",
            }}
          >
            <IconButton
              sx={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                color: theme.palette.sidebar?.text,
              }}
              onClick={() => setOpenTemp(false)}
            >
              <KeyboardTabIcon sx={{ transform: "rotate(-180deg)" }} />
            </IconButton>
          </Box>
          <Box sx={{ marginTop: "50px" }}>
            <LeftPanel
              sidebarClose={() => {}}
              setOpenTemp={setOpenTemp}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              getHistory={getHistory}
              searchHistory={searchHistory}
              setSearchHistory={setSearchHistory}
              // getBookmark={getBookmark}
            />
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default SideMobileDrawer;
