import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import  { useRef } from "react";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Properties } from "./properties";
import CloseIcon from "@mui/icons-material/Close";
import ChatWithPatent from "./ChatWithPatent";

const PatentDetailsModal = ({ results, detailsFor, handleClose }: any) => {
  const sliderRef = useRef<Slider | null>(null);
const theme = useTheme()
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: {
          xs: "90%", // 90% width on extra small screens
          sm: "80%", // 80% width on small screens
          md: "70%", // 70% width on medium screens
          lg: "60%", // 60% width on large screens
          xl: "60%", // 50% width on extra large screens
        },
        bgcolor: theme.palette.rightPanel?.background,
        color:theme.palette.rightPanel?.text,
        boxShadow: 24,
        overflowY: "auto",
        p: 4,
        maxHeight: "90vh",
        fontFamily: "inter",
      }}
    >
      <IconButton
        onClick={() => handleClose()}
        style={{
          zIndex: "99",
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h6" fontWeight={700}>
        Patent Details
      </Typography>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {results?.length > 0 && (
              <Stack>
                <Properties
                  keyString="Patent name"
                  value={results[detailsFor]?.["title"] ?? "-"}
                />
                <Properties
                  keyString="Patent Number"
                  value={results[detailsFor]?.["id"] ?? "-"}
                />
                <Properties
                  keyString="Description"
                  value={results[detailsFor]?.["abstract"] ?? "-"}
                />
                <Properties
                  keyString="Owner"
                  value={results[detailsFor]?.["owner"] ?? "-"}
                />
                <Properties
                  keyString="Inventors"
                  value={results[detailsFor]?.["inventors"] ?? "-"}
                />
                <Properties
                  keyString="CPC subclass"
                  value={results[detailsFor]?.["index"] ?? "-"}
                />
                {results[detailsFor] && results[detailsFor].website && (
                  <Stack
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => window.open(results[detailsFor].website)}
                      style={{ margin: "20px 0px 20px 0px", width: "100%" }}
                    >
                      Visit website
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}

            {results?.result && (
              <Stack>
                <Properties
                  keyString="Patent name"
                  value={results.result.title ?? "-"}
                />
                <Properties
                  keyString="Patent Number"
                  value={results.result.id ?? "-"}
                />
                <Properties
                  keyString="Description"
                  value={results.result?.abstract ?? "-"}
                />
                <Properties
                  keyString="Owner"
                  value={results.result?.owner ?? "-"}
                />
                <Properties
                  keyString="Inventors"
                  value={results.result?.inventors ?? "-"}
                />
                <Properties
                  keyString="CPC subclass"
                  value={results.result?.index ?? "-"}
                />
                {results.result && results.result.website && (
                  <Stack
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => window.open(results.result.website)}
                      style={{ margin: "20px 0px 20px 0px", width: "100%" }}
                    >
                      Visit website
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}
          </Grid>

          {/* Box 2 */}
          <Grid item xs={12} sm={6}>
            {results?.result && (
              <img
                src={results?.result?.image}
                alt="Slide"
                width={"100%"}
                height={"250px"}
                style={{ objectFit: "cover" }}
              />
            )}
            {results?.length > 0 && (
              <Box
                sx={{
                  maxWidth: 600,
                  margin: "0 auto",
                  padding: "20px",
                  position: "relative",
                }}
              >
                <Slider ref={sliderRef} {...settings}>
                  {/* Slide 1 */}
                  {results.map((result: any) => (
                    <Box key={result.id}>
                      <img
                        src={result.image}
                        alt="Slide"
                        width={"100%"}
                        height={"250px"}
                        style={{ objectFit: "cover" }}
                      />
                    </Box>
                  ))}
                </Slider>
                {/* Left Arrow */}
                <IconButton
                  onClick={() =>
                    sliderRef.current && sliderRef.current.slickPrev()
                  }
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Background color with opacity
                    color: "#fff",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                    height: "40px", // Explicit height
                    width: "40px", // Explicit width
                    display: "flex", // Ensures proper centering inside the button
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    minWidth: "40px",
                    padding: "6px 0px",
                    paddingLeft: "10px",
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>

                {/* Right Arrow */}
                <IconButton
                  onClick={() =>
                    sliderRef.current && sliderRef.current.slickNext()
                  }
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Background color with opacity
                    color: "#fff",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            )}
            {/* </Box> */}
          </Grid>
        </Grid>
      </Box>
      <Box>
         <ChatWithPatent
          currentUserId={"user1"}
          recipientId={"user2"}
        />
      </Box>
    </Box>
  );
};

export default PatentDetailsModal;
